body,html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
    position: relative;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #00ADC3 !important;
}

.ant-tabs .ant-tabs-ink-bar {
    background: #00ADC3 !important;
}

.ant-select-outlined.ant-select-multiple .ant-select-selection-item {
    background: #00ADC3 !important;
}

.ant-tabs-top >.ant-tabs-nav::before {
    border-bottom: 0;
}

.ant-select-multiple .ant-select-selection-item-remove {
    color: #F2F3F5 !important;
    opacity: 0.6;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #D4DCE5;
}

.ant-select .ant-select-arrow {
    color: #061E3B;
}
